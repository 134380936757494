.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 20px;
    width: 100%;
    transition: transform 0.5s ease-in-out; /* Transition for smooth sliding with ease-in-out effect */
    z-index: 1000;
  }

  .navbar.gj {
    top: 10px;
  }
  
  .navbar.scrolled {
    transform: translateY(-105%); /* Slide the blue section up */
    background: transparent; /* Change to your desired color */
  }
  
  .logo {
    /* Adjust width and height as needed */
    width: 220px;
    height: auto; /* Automatically adjust height to maintain aspect ratio */
  }

  .logo.gj {
    /* Adjust width and height as needed */
    width: 600px;
    height: auto; /* Automatically adjust height to maintain aspect ratio */
  }
  
  .logo img {
    max-width: 100%;
    height: auto;
  }
  
  
  .nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-right: 20px;
  }
  
  .nav-links li {
    margin-left: 20px;
  }

  /* Styles for desktop */
.desktop-logo {
  display: block;
}

img.mobile-logo {
  display: none;
}

img.mobile-logo-gj {
  display: none;
}

  
  /* Media query for mobile view */
  @media (max-width: 991px) {
    .navbar {
      display: none;
    }
    
    .mobile-logo {
      display: block;
    }

    .mobile-logo-gj {
      display: block;
    }

     img.mobile-logo {
      display: block;
      height: 80px;
      width: auto;
      position: absolute;
      top: 20px;
      left:20px;
      padding: 10px;
      filter: brightness(200%); /* Adjust the percentage as needed */
    }

    img.mobile-logo-gj {
      display: block;
      height: 120px;
      width: auto;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      margin: auto;
      padding: 0px;
    }


    img.mobile-logo.logo-spinner {
      display: block;
      height: 100px;
      width: auto;
      position: absolute;
      top: -40px;
      left: -40px;
      padding: 10px;
      filter: brightness(200%); /* Adjust the percentage as needed */
    }
  }


    
  /* Media query for mobile view */
  @media (max-width: 1200px) {

     .navbar {
      top: 40px;
    }
  }


  
  